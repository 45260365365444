/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.shouldUpdateScroll = () => false;

require('./src/css/components.css');
require('./src/css/components/about.css');
require('./src/css/components/contact.css');
require('./src/css/components/expandBox.css');
require('./src/css/components/FAQ.css');
require('./src/css/components/footer.css');
require('./src/css/components/index.css');
require('./src/css/components/lightbox.css');
require('./src/css/components/misc.css');
require('./src/css/components/nav.css');
require('./src/css/components/pricing.css');
require('./src/css/components/shows.css');

require('@fontsource/montserrat/100.css');
require('@fontsource/montserrat/200.css');
require('@fontsource/montserrat/300.css');
require('@fontsource/montserrat/400.css');
require('@fontsource/montserrat/500.css');
require('@fontsource/montserrat/600.css');
require('@fontsource/montserrat/700.css');
require('@fontsource/montserrat/800.css');
require('@fontsource/montserrat/900.css');
require('@fontsource/mulish/200.css');
require('@fontsource/mulish/300.css');
require('@fontsource/mulish/400.css');
require('@fontsource/mulish/500.css');
require('@fontsource/mulish/600.css');
require('@fontsource/mulish/700.css');
require('@fontsource/mulish/800.css');
require('@fontsource/mulish/900.css');
