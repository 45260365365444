exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/FAQ.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-slug-tsx": () => import("./../../../src/pages/{Legal.slug}.tsx" /* webpackChunkName: "component---src-pages-legal-slug-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-pricing-package-jso-nid-tsx": () => import("./../../../src/pages/pricing/{Package.JSONid}.tsx" /* webpackChunkName: "component---src-pages-pricing-package-jso-nid-tsx" */),
  "component---src-pages-shows-index-tsx": () => import("./../../../src/pages/shows/index.tsx" /* webpackChunkName: "component---src-pages-shows-index-tsx" */),
  "component---src-pages-shows-package-jso-nid-tsx": () => import("./../../../src/pages/shows/{Package.JSONid}.tsx" /* webpackChunkName: "component---src-pages-shows-package-jso-nid-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

